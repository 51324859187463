import React, { useState } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import RouteWrapper from "../auth/RouteWrapper";
import Index from "./Index";
import Header from "../utils/Header";
import Menu from "../utils/Menu";
import HomeFocus from "../focus/Home";
import HomeEmerg from "../emerg/Home";
import HomeAdmin from "../admin/Home";
import NotFound from "./NotFound";
import Unauthorized from "../auth/Unauthorized";
import HomeFAOFaculty from "../fao/Faculty/Home";
import HomeFAOTitles from "../fao/Titles/Home";
import HomeFAOProfessorships from "../fao/Professorships/Home";
import HomeFAOBuildings from "../fao/Buildings/Home";
import HomeFAODepartments from "../fao/Departments/Home";
import HomeFAODivisions from "../fao/Divisions/Home";
import HomeFAOPracticePlans from "../fao/PracticePlans/Home";
import HomeFAOPrivLoc from "../fao/PrivilegeLocations/Home";
import HomeFAOHSCSponsors from "../fao/HSCSponsors/Home";
import HomeFAOUsersSearch from "../fao/UsersSearch/Home";
import HomeFAOMissingData from "../fao/MissingData/Home";
import { useUserContext } from "../context/UserContext";
import HomeFAOPerformanceGroup from "../fao/PerformanceGroup/Home";

const Routes = () => {
  const [openMenu, setOpenMenu] = useState(false);
  let { isAuthenticated } = useUserContext();

  return (
    <>
      {isAuthenticated ? (
        <BrowserRouter>
          <Menu openMenu={openMenu} setOpenMenu={setOpenMenu} />
          <Header setOpenMenu={setOpenMenu} />
          <Switch>
            <RouteWrapper
              exact
              perform="homepage:visit"
              path="/"
              ComponentYes={Index}
              ComponentNo={Unauthorized}
              navHeader="Home"
            />
            <RouteWrapper
              perform="homepage:visit"
              path="/index"
              ComponentYes={Index}
              ComponentNo={Unauthorized}
              navHeader="Home"
            />
            <RouteWrapper
              perform="admin-pages:visit"
              path="/admin"
              ComponentYes={HomeAdmin}
              ComponentNo={Unauthorized}
              navHeader="Admin"
            />
            <RouteWrapper
              perform="awome-pages:visit"
              path="/awome/focus"
              ComponentYes={HomeFocus}
              ComponentNo={Unauthorized}
              navHeader="Focus"
            />
            <RouteWrapper
              perform="awome-pages:visit"
              path="/awome/emerg"
              ComponentYes={HomeEmerg}
              ComponentNo={Unauthorized}
              navHeader="eMerg"
            />
            <RouteWrapper
              perform="fao-pages:visit"
              path="/fao/faculty"
              ComponentYes={HomeFAOFaculty}
              ComponentNo={Unauthorized}
              navHeader="FAO Faculty"
            />
            <RouteWrapper
              perform="fao-pages:visit"
              path="/fao/titles"
              ComponentYes={HomeFAOTitles}
              ComponentNo={Unauthorized}
              navHeader="FAO Titles"
            />
            <RouteWrapper
              perform="fao-pages:visit"
              path="/fao/professorships"
              ComponentYes={HomeFAOProfessorships}
              ComponentNo={Unauthorized}
              navHeader="FAO Professorships"
            />
            <RouteWrapper
              perform="fao-pages:visit"
              path="/fao/buildings"
              ComponentYes={HomeFAOBuildings}
              ComponentNo={Unauthorized}
              navHeader="FAO Buildings"
            />
            <RouteWrapper
              perform="fao-pages:visit"
              path="/fao/departments"
              ComponentYes={HomeFAODepartments}
              ComponentNo={Unauthorized}
              navHeader="FAO Departments"
            />
            <RouteWrapper
              perform="fao-pages:visit"
              path="/fao/divisions"
              ComponentYes={HomeFAODivisions}
              ComponentNo={Unauthorized}
              navHeader="FAO Divisions"
            />
            <RouteWrapper
              perform="fao-pages:visit"
              path="/fao/practice-plans"
              ComponentYes={HomeFAOPracticePlans}
              ComponentNo={Unauthorized}
              navHeader="FAO Practice Plans"
            />
            <RouteWrapper
              perform="fao-pages:visit"
              path="/fao/privloc"
              ComponentYes={HomeFAOPrivLoc}
              ComponentNo={Unauthorized}
              navHeader="FAO Privileges Location"
            />
            <RouteWrapper
              perform="fao-pages:visit"
              path="/fao/hsc-sponsors"
              ComponentYes={HomeFAOHSCSponsors}
              ComponentNo={Unauthorized}
              navHeader="FAO HSC Sponsors"
            />
            <RouteWrapper
              perform="fao-pages:visit"
              path="/fao/users-search"
              ComponentYes={HomeFAOUsersSearch}
              ComponentNo={Unauthorized}
              navHeader="FAO Users Search"
            />
            <RouteWrapper
              perform="fao-pages:visit"
              path="/fao/missing-data"
              ComponentYes={HomeFAOMissingData}
              ComponentNo={Unauthorized}
              navHeader="FAO Missing Data"
            />
            <RouteWrapper
              perform="fao-pages:visit"
              path="/fao/performance-group"
              ComponentYes={HomeFAOPerformanceGroup}
              ComponentNo={Unauthorized}
              navHeader="FAO Performance Group"
            />
            <Route component={NotFound} />
          </Switch>
        </BrowserRouter>
      ) : (
        <>
          <Header blank />
          <Index />
        </>
      )}
    </>
  );
};

export default Routes;
