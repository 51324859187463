import React from "react";
import ToolMenu from "../../utils/ToolMenu";
import PerformanceGroupAdmin from "./PerformanceGroupAdmin";
import { Route, Switch } from "react-router-dom";

const HomeFAOPerformanceGroup = () => {
  return (
    <div className="main-content-outer">
      <div className="main-content-inner constrain-1260">
        <ToolMenu app="faoPerformanceGroup" appURL="/fao/performance-group" />
        <div className="app-content-container">
          <Switch>
            <Route
              exact
              path="/fao/performance-group"
              component={PerformanceGroupAdmin}
            />
          </Switch>
        </div>
      </div>
    </div>
  );
};

export default HomeFAOPerformanceGroup;
