import React from "react";
import PerformanceGroupFileUpload from "./PerformanceGroupFileUpload";

const PerformanceGroupAdmin = () => {
    return (
        <div className="privilege-location-container">
            <div className="tool-title">FAO Override Perfromance Group</div>
                <div className="tool-description-container">
                    <div className="tool-description-label">Tool Description:</div>
                        <div className="tool-description">
                            Bulk update/insert faculty performance group. The Excel file must contain the headers FAC_REC_ID and GROUP.
                            <br />The following are valid groups: 
                            <ul>
                                <li>A</li>
                                <li>B</li>
                                <li>C</li>
                                <li>D</li>
                                <li>Not Required</li>
                                <li>Exempt</li>
                            </ul>
                        </div>
            </div>
            <div className="table-container">
                <div className="table-wrap">
                    <PerformanceGroupFileUpload />
                 </div>
            </div>
        </div>
    )
}

export default PerformanceGroupAdmin