import React, {useCallback, useMemo, useState} from 'react';
import * as XLSX from 'xlsx'; 
import axios from 'axios'; 
import { useUserContext } from "../../context/UserContext";
import {useDropzone} from 'react-dropzone';
import Loader from "../../utils/Loader";
import Config from "../../config/Config";

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  outline: 'none',
  transition: 'border .24s ease-in-out'
};

const activeStyle = {
  borderColor: '#2196f3'
};

const acceptStyle = {
  borderColor: '#00e676'
};

const rejectStyle = {
  borderColor: '#ff1744'
};

const PerformanceGroupFileUpload = () => {

    // user
    const context = useUserContext();
    let { netid } = context.userUT || {};

    // api 
    let { fao_admin_api_root, fao_admin_api_key } = Config;

    const [performanceGroupData, setPerfromanceGroupData] = useState();
    const [apiMessage, setAPIMessage] = useState({
        loading: false,
        error: false,
        ready: false,
        messageText: "Loading...",
      });

    const onDrop = useCallback(acceptedFiles => {
        acceptedFiles.forEach((file) => {
            const fileReader = new FileReader(); 
            fileReader.onload = () => {
                
                //process file contents
                const bufferArray = fileReader.result; 
                // workbook
                const wb = XLSX.read(bufferArray, {type: 'buffer'})
                
                // get the first sheet in the workbook
                const wsname = wb.SheetNames[0]; 
                
                // get the sheet name
                const ws = wb.Sheets[wsname]; 

                // validate header row
                let validHeaders = validateHeaderRow(ws); 
                
                if (validHeaders) {
                    // convert excel data to json
                    let data = XLSX.utils.sheet_to_json(ws); 

                    // set state
                    setPerfromanceGroupData(data); 
                }
                else {
                    alert("Invalid Headers"); 
                    return;                     
                }

            }
            fileReader.readAsArrayBuffer(file)
        })
    }, [])

    const validateHeaderRow = (ws) => {
        // get headers
        var headers = []; 
        var range = XLSX.utils.decode_range(ws['!ref']); 
        var C, R = range.s.r; // start with the first row
        // walk every column in the range 
        for (C = range.s.c; C <= range.e.c; C++) {
            var cell = ws[XLSX.utils.encode_cell({c:C, r:R})] // find the cell in the first row

            var hdr = "UNKNOWN " + C; // <-- replace with your desired default 
            if(cell && cell.t) hdr = XLSX.utils.format_cell(cell);

            headers.push(hdr.toLocaleLowerCase());
        }
        
        if (headers.includes("fac_rec_id") && headers.includes("group"))
            return true; 
        else 
            return false; 
    }

    const {
        acceptedFiles,
        getRootProps,
        getInputProps,
        open,
        isDragActive,
        isDragAccept,
        isDragReject
      } = useDropzone({
        maxFiles:1,
        // Disable click and keydown behavior
        noClick: true,
        noKeyboard: true,
        // read file and parse as json
        onDrop,
        accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
      });

    const style = useMemo(() => ({
    ...baseStyle,
    ...(isDragActive ? activeStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isDragActive,
    isDragReject,
    isDragAccept
  ]);

  /*
  const handleRemoveFile = (acceptedFiles) => {
      acceptedFiles.splice(0, acceptedFiles.length); 
  }
  */

  const file = acceptedFiles.map(file => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
      {/* <button onClick={() => handleRemoveFile(acceptedFiles)}>Remove File</button>*/}

    </li>
  ));

  const handleSubmit = () => {
    console.log(performanceGroupData); 
    
    let message = {
        modify_user: netid,
        data: performanceGroupData,
      };

    console.log(message); 

    setAPIMessage({
        loading: true, 
        ready: false,
    }); 
    
    axios
    .post(`${fao_admin_api_root}/faoadmin/performancegroup`, message, {
      headers: {
        "Content-Type": "application/json",
        "x-api-key": fao_admin_api_key,
      },
    })
    .then((response) => {
      if (!response.data.error) {
        console.log("Success"); 
        setAPIMessage({
            loading: false, 
            ready: true,
            message: "processing...",
        })
      } else {
        // Internal Error
        console.log("Error"); 
      }
    })
    .catch((error) => {
      console.log(error);
      setAPIMessage({
        loading: false, 
        ready: true,
        message: "processing...",
    })
    });
    
};

  return (
    <div className="container">
      <div {...getRootProps({style})}>
        <input {...getInputProps()} />
        {apiMessage.loading && <Loader message={apiMessage} />}
        <p>Drag 'n' drop file here, or click Select File button</p>
        <em>(Maximum of one file)</em>
        <em>(Only .xls or .xlsx files will be accepted)</em>
        <button type="button" onClick={open}>
          Select File
        </button>
        <br />
        <button type="button" onClick={handleSubmit}>
            Submit
        </button>
      </div>
      <aside>
        <h4>File</h4>
        <ul>{file}</ul>
      </aside>    
    </div>
  );
}

export default PerformanceGroupFileUpload; 